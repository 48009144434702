import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  * {
    box-sizing: border-box;
  }
  overflow: hidden;
`;

export const LoadingScreen = styled.div`
  .st0 {
    fill: #50b376;
  }
  .st1 {
    fill: #1d1d1b;
  }
  svg {
    max-width: 200px;
    width: 100%;
    cursor: normal;
  }
`;
