import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './utils/Swal/reset-swal-style.css';
import './index.css';

import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';

import {Provider} from 'react-redux';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import App from './App';
import store from './redux/store';
import GlobalStyle from './styles';

import LoadingScreen from './templates/LoadingScreen';

Sentry.init({
  dsn: 'https://51a2da7078fd46a9aec06c5915b9c484@o1169521.ingest.sentry.io/6264173',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <>
    <Provider store={store}>
      <GlobalStyle />
      <Suspense fallback={<LoadingScreen />}>
        <App />
      </Suspense>
    </Provider>
  </>,
  document.getElementById('root'),
);
