import React from 'react';
import {motion} from 'framer-motion';

import * as S from './styles';

const defaultVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,

    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
};

function LoadingScreen() {
  return (
    <S.Container>
      <S.LoadingScreen>
        <motion.svg
          variants={defaultVariants}
          initial="hidden"
          animate="visible"
          version="1.1"
          id="Camada_1"
          x="0px"
          y="0px"
          viewBox="0 0 800 260">
          <path
            className="st0"
            d="M649.72 183.74c1.18-2.44 2.78-5.5 4.8-9.16c2.02-3.66 4.21-7.63 6.57-11.91c2.36-4.28 4.8-8.74 7.32-13.4
	c2.53-4.66 5.05-9.27 7.58-13.86c5.72-10.69 11.87-21.91 18.44-33.67c14.24-8.59 29.6-13.45 45.71-15.8
	c-7.41 13.44-14.48 26.41-21.21 38.93c-2.86 5.34-5.81 10.8-8.84 16.38c-3.03 5.57-6.02 10.99-8.97 16.26
	c-2.95 5.27-5.68 10.23-8.21 14.89c-2.53 4.66-4.71 8.67-6.57 12.02c-3.87 6.87-6.95 12.56-9.22 17.06
	c-2.27 4.5-3.92 8.09-4.92 10.76c-1.01 2.67-1.35 4.54-1.01 5.61c0.34 1.07 1.26 1.6 2.78 1.6c2.86 0 6.23-1.76 10.1-5.27
	c3.87-3.51 7.49-7.41 10.86-11.68c4.04-5.04 8.17-10.69 12.38-16.95c-3.7 8.25-8.17 15.57-13.39 21.99
	c-2.19 2.75-4.63 5.42-7.32 8.02c-2.69 2.6-5.68 4.92-8.97 6.99c-3.28 2.06-6.82 3.7-10.61 4.92c-3.79 1.22-7.79 1.83-12 1.83
	c-7.91 0-13.13-1.45-15.66-4.35c-2.53-2.9-3.45-6.57-2.78-10.99c0.67-4.43 2.36-9.35 5.05-14.77
	C644.33 193.78 647.02 188.63 649.72 183.74z"
          />
          <path
            className="st0"
            d="M594.41 183.74c2.53-4.89 5.09-9.73 7.7-14.54c2.61-4.81 5.01-9.2 7.2-13.17c2.53-4.58 4.88-8.93 7.07-13.05
	c13.63-5.27 26.9-6.64 39.9-5.04c-2.19 3.82-4.55 8.17-7.07 13.05c-2.36 4.12-5.01 9.01-7.96 14.66
	c-2.95 5.65-6.36 11.91-10.23 18.78c-3.87 6.87-6.95 12.56-9.22 17.06c-2.27 4.5-3.92 8.09-4.93 10.76
	c-1.01 2.67-1.35 4.54-1.01 5.61c0.34 1.07 1.26 1.6 2.78 1.6c2.86 0 6.23-1.76 10.1-5.27c3.87-3.51 7.49-7.41 10.86-11.68
	c4.04-5.04 8.17-10.69 12.38-16.95c-3.7 8.25-8.17 15.57-13.39 21.99c-2.19 2.75-4.63 5.42-7.32 8.02c-2.69 2.6-5.68 4.92-8.97 6.99
	c-3.28 2.06-6.82 3.7-10.61 4.92c-3.79 1.22-7.79 1.83-12 1.83c-7.91 0-13.13-1.45-15.66-4.35c-2.53-2.9-3.45-6.57-2.78-10.99
	c0.67-4.43 2.36-9.35 5.05-14.77C589.02 193.78 591.71 188.63 594.41 183.74z"
          />
          <path
            className="st0"
            d="M537.33 183.74c2.69-4.89 5.35-9.73 7.96-14.54c2.61-4.81 5.09-9.2 7.45-13.17c2.53-4.58 5.05-8.93 7.58-13.05
	c13.3-4.28 26.6-5.75 39.9-5.04c-2.19 3.82-4.63 8.17-7.32 13.05c-2.36 4.12-5.09 9.01-8.21 14.66
	c-3.12 5.65-6.61 11.91-10.48 18.78c-3.87 6.87-6.99 12.48-9.34 16.83c-2.36 4.35-4 7.79-4.92 10.31c-0.93 2.52-1.22 4.27-0.88 5.27
	c0.34 0.99 1.26 1.49 2.78 1.49c2.36 0 5.72-2.25 10.1-6.76c4.38-4.5 9.05-10.12 14.02-16.83c4.97-6.72 9.85-14.01 14.65-21.87
	c4.8-7.86 8.8-15.23 12-22.1c-5.72 12.37-11.15 23.4-16.29 33.09c-5.14 9.7-10.27 17.94-15.41 24.73
	c-5.14 6.79-10.52 11.95-16.16 15.46c-5.64 3.51-11.83 5.27-18.56 5.27c-7.91 0-13.51-1.07-16.8-3.21
	c-3.28-2.14-4.97-5.19-5.05-9.16c-0.08-3.97 1.14-8.74 3.66-14.31C530.51 197.07 533.62 190.77 537.33 183.74z"
          />
          <path
            className="st0"
            d="M511.57 137.94c11.79 0 18.73 3.78 20.84 11.34c2.1 7.56-1.73 19.28-11.49 35.16
	c-7.58 12.67-12.71 21.68-15.41 27.03c-2.69 5.34-2.53 8.02 0.51 8.02c1.51 0 3.11-0.5 4.8-1.49c1.68-0.99 3.49-2.29 5.43-3.89
	c1.94-1.6 3.83-3.4 5.68-5.38c1.85-1.98 3.7-4.05 5.56-6.18c4.21-5.04 8.42-10.69 12.63-16.95c-3.7 8.25-8.17 15.57-13.39 21.99
	c-2.19 2.75-4.63 5.42-7.32 8.02c-2.69 2.6-5.68 4.92-8.97 6.99c-3.28 2.06-6.82 3.7-10.61 4.92c-3.79 1.22-7.79 1.83-12 1.83
	c-7.91 0-13.05-1.49-15.41-4.47c-2.36-2.98-3.07-6.68-2.15-11.11c0.93-4.43 2.82-9.31 5.68-14.66c2.86-5.34 5.72-10.46 8.59-15.34
	c2.69-4.73 5.13-9.16 7.32-13.28c2.19-4.12 3.91-7.64 5.18-10.54c1.26-2.9 1.85-5.19 1.77-6.87c-0.08-1.68-1.14-2.52-3.16-2.52
	c-1.35 0-2.99 0.88-4.93 2.63c-1.94 1.76-4.04 4.08-6.31 6.99c-2.27 2.9-4.59 6.18-6.95 9.85c-2.36 3.66-4.63 7.41-6.82 11.22
	c-5.39 9.01-10.86 19.16-16.42 30.46c0-1.07 0.42-2.98 1.26-5.73c0.84-2.29 2.23-5.57 4.17-9.85c1.94-4.28 4.84-10 8.71-17.18
	c2.69-4.73 5.39-9.54 8.08-14.43c2.69-4.89 5.68-9.31 8.97-13.28c3.28-3.97 7.03-7.18 11.24-9.62
	C500.88 139.16 505.84 137.94 511.57 137.94z"
          />
          <path
            className="st0"
            d="M469.39 183.52c-2.69 5.04-5.47 10.27-8.33 15.69c-2.86 5.42-5.56 10.34-8.08 14.77
	c-3.03 5.19-5.89 10.38-8.59 15.57h-37.13c2.02-3.82 4.38-8.17 7.07-13.05c2.36-4.12 5.05-9.05 8.08-14.77
	c3.03-5.73 6.48-12.02 10.35-18.89c3.87-6.87 6.95-12.41 9.22-16.61c2.27-4.2 3.83-7.44 4.67-9.73c0.84-2.29 1.05-3.86 0.63-4.7
	c-0.42-0.84-1.39-1.26-2.9-1.26c-1.35 0-2.99 0.84-4.92 2.52c-1.94 1.68-4 3.93-6.19 6.76c-2.19 2.83-4.46 6.03-6.82 9.62
	c-2.36 3.59-4.63 7.21-6.82 10.88c-5.22 8.7-10.69 18.55-16.42 29.54c1.52-4.12 3.03-8.09 4.55-11.91c1.35-3.21 2.69-6.45 4.04-9.73
	c1.35-3.28 2.61-5.84 3.79-7.67c2.02-3.21 4.34-7.21 6.95-12.02c2.61-4.81 5.81-9.51 9.6-14.09c3.79-4.58 8.29-8.47 13.51-11.68
	c5.22-3.21 11.37-4.81 18.44-4.81c7.75 0 12.88 1.45 15.41 4.35c2.53 2.9 3.49 6.6 2.9 11.11c-0.59 4.5-2.23 9.43-4.93 14.77
	C474.78 173.51 472.08 178.63 469.39 183.52z"
          />
          <path
            className="st0"
            d="M355.49 229.55l46.98-86.57c12.87-4.4 26.15-5.64 39.65-5.04l-49.5 91.61H355.49z"
          />
          <path
            className="st0"
            d="M366.6 137.94c8.59 0 14.44 1.34 17.55 4.01c9.59 8.22 0.37 30.25-6.82 35.84
	c-12.73 9.76-27.92 15.3-45.02 17.57c-3.01 0.4 11.98-25.21 15.35-30.97c2.19-3.74 3.75-6.83 4.67-9.28
	c0.93-2.44 0.29-3.66-1.89-3.66c-3.03 0-6.06 1.3-9.09 3.89c-3.03 2.6-5.72 5.42-8.08 8.47c-2.86 3.66-5.47 7.71-7.83 12.14
	c-1.35 1.68-2.78 3.24-4.29 4.69c-1.52 1.45-2.82 2.71-3.92 3.78c-1.09 1.07-1.98 1.79-2.65 2.18c-0.67 0.38-0.84 0.27-0.5-0.34
	c3.03-6.26 6.57-12.29 10.61-18.09c4.04-5.8 8.33-10.95 12.88-15.46c4.55-4.5 9.26-8.09 14.14-10.76
	C356.58 139.28 361.55 137.94 366.6 137.94z"
          />
          <path
            className="st0"
            d="M259.51 229.55l46.98-86.57c13.3-4.73 26.6-5.93 39.9-5.04l-49.5 91.61H259.51z"
          />
          <path
            className="st0"
            d="M222.39 225.43c11.45 0 21.93-2.9 31.44-8.7c9.51-5.8 18.82-13.51 27.91-23.13c-3.2 4.73-7.2 9.27-12 13.63
	c-4.8 4.35-10.1 8.21-15.91 11.57c-5.81 3.36-12 6.03-18.56 8.02c-6.57 1.98-13.39 2.98-20.46 2.98c-10.94 0-19.19-2.25-24.75-6.76
	c-5.56-4.5-8.71-10.19-9.47-17.06c-0.76-6.87 0.63-14.24 4.17-22.1c3.54-7.86 8.84-15.23 15.91-22.1
	c7.07-6.87 15.66-12.56 25.76-17.06c10.1-4.5 21.38-6.76 33.84-6.76c8.42 0 14.82 1.45 19.19 4.35c4.38 2.9 6.9 6.57 7.58 10.99
	c0.67 4.43-0.51 9.31-3.54 14.66c-3.03 5.34-7.83 10.5-14.4 15.46c-6.57 4.96-14.73 9.35-24.5 13.17
	c-9.77 3.82-21.13 6.49-34.1 8.02c-2.19-0.76-2.82-1.53-1.89-2.29c0.93-0.76 1.98-1.22 3.16-1.37c4.55-0.46 9.13-2.25 13.76-5.38
	c4.63-3.13 8.97-6.91 13.01-11.34c4.04-4.43 7.75-9.12 11.11-14.09c3.37-4.96 6.02-9.54 7.96-13.74c1.94-4.2 3.11-7.71 3.54-10.54
	c0.42-2.82-0.29-4.24-2.15-4.24c-2.36 0-5.72 2.06-10.1 6.18c-4.38 4.12-8.92 9.31-13.64 15.57c-4.71 6.26-9.13 12.98-13.26 20.15
	c-4.12 7.18-7.16 13.89-9.09 20.15c-1.94 6.26-2.36 11.45-1.26 15.57C212.75 223.37 216.33 225.43 222.39 225.43z"
          />
          <path
            className="st0"
            d="M28.87 229.85c31.39-61.37 49.41-96.5 80.8-157.86c9.81-19.16 37.42-20.67 56.82-15.57l-52.81 104.75
	l-2.27-14.94l-44.98 44.2l24.41 14.63l-12.19 24.8H28.87z"
          />
          <path
            className="st0"
            d="M74.13 160.26c-22.9 1.28-52.59-15.03-54.12-37.56c-1.44-21.21 14.45-40.93 30.73-53.83
	c75.45-59.8 211.66-50.42 173.21 45.85c-17.34 43.41-64.66 70-111.89 74.54l2.91 23.04l-39.82-22.7l33.06-32.3l2.67 23.33
	c4.73-1.19 9.98-3.26 16.03-7.61c27.38-19.71 53.6-59.52 57.46-91.69c1.23-10.22-0.39-18.74-4.84-25.56
	c-4.45-6.82-12.81-10.22-25.05-10.22c-13.58 0-25.61 2.29-36.08 6.87C81.57 68.53 28.55 131 74.13 160.26z"
          />
          <polygon
            className="st0"
            points="661.33 137.94 666.89 129.01 731.8 129.01 726.5 137.94 "
          />
          <path
            className="st0"
            d="M705.03 183.74c2.69-4.89 5.3-9.73 7.83-14.54c2.53-4.81 4.88-9.2 7.07-13.17c2.36-4.58 4.71-8.93 7.07-13.05
	c12.98-4.56 26.3-6.1 39.9-5.04c-2.02 3.82-4.29 8.17-6.82 13.05c-2.19 4.12-4.84 9.01-7.96 14.66
	c-3.12 5.65-6.61 11.91-10.48 18.78c-3.87 6.87-6.95 12.56-9.22 17.06c-2.27 4.5-3.92 8.09-4.92 10.76
	c-1.01 2.67-1.35 4.54-1.01 5.61c0.34 1.07 1.26 1.6 2.78 1.6c2.86 0 6.23-1.76 10.1-5.27c3.87-3.51 7.49-7.41 10.86-11.68
	c4.04-5.04 8.17-10.69 12.37-16.95c-3.7 8.25-8.08 15.57-13.13 21.99c-2.19 2.75-4.63 5.42-7.32 8.02c-2.69 2.6-5.68 4.92-8.97 6.99
	c-3.28 2.06-6.82 3.7-10.61 4.92c-3.79 1.22-7.79 1.83-12 1.83c-7.91 0-13.13-1.45-15.66-4.35c-2.53-2.9-3.49-6.57-2.9-10.99
	c0.59-4.43 2.23-9.35 4.92-14.77C699.64 193.78 702.33 188.63 705.03 183.74z"
          />
          <path
            className="st1"
            d="M759.07 129.01c10.96 0 19.89-8.48 19.89-18.87c0-10.4-8.94-18.87-19.89-18.87s-19.89 8.48-19.89 18.87 C739.18 120.53 748.12 129.01 759.07 129.01z"
          />
        </motion.svg>
      </S.LoadingScreen>
    </S.Container>
  );
}

export default LoadingScreen;
