import React, {useEffect, useState} from 'react';
import Routes from './routes/routes';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {BrowserRouter} from 'react-router-dom';
import {getCoin} from './services/Geolocation/getcoin';
import {useDispatch} from 'react-redux';
import {io} from 'socket.io-client';
import I18n from './i18n/i18';
import Notification_Socket from './redux/action/action_notication_socket';
import {getRoute} from './utils';
import {Helmet} from 'react-helmet';
import * as Sentry from '@sentry/react';

function App() {
  useEffect(() => {
    if (!localStorage.getItem('currency'))
      localStorage.setItem('currency', 'USD');

    if (!localStorage.getItem('length_unit'))
      localStorage.setItem('length_unit', 'sqft');

    if (!localStorage.getItem('currency')) getCoin();
  }, []);

  /**Sockets Notifications*/

  const dispatch = useDispatch();
  const id = localStorage.getItem('id');

  useEffect(() => {
    const socket = io(process.env.REACT_APP_BACKEND);
    if (id) {
      socket.on(`notifications_${id}`, (data) => {
        console.log(data);
        dispatchMessage(data);
      });
    } else {
      // socket.on('disconnect', () => {
      //   console.log('Client disconnected');
      // });
    }
  }, [id]);

  function dispatchMessage(data) {
    dispatch(Notification_Socket(data));
  }

  /** */
  return (
    <>
      <Helmet>
        <style type="text/css">{`
       #iubenda-cs-banner {
        bottom: 0px !important;
        left: 0px !important;
        position: fixed !important;
        width: 100% !important;
        z-index: 99999998 !important;
        background-color: black;
      }
      .iubenda-cs-content {
        display: block;
        margin: 0 auto;
        padding: 20px;
        width: auto;
        font-family: Helvetica,Arial,FreeSans,sans-serif;
        font-size: 14px;
        background: #000;
        color: #fff;}
      .iubenda-cs-rationale {
        max-width: 900px;
        position: relative;
        margin: 0 auto;
      }
      .iubenda-banner-content > p {
        font-family: Helvetica,Arial,FreeSans,sans-serif;
        line-height: 1.5;
      }
      .iubenda-cs-close-btn {
        margin:0;
        color: #fff;
        text-decoration: none;
        font-size: 14px;
        position: absolute;
        top: 0;
        right: 0;
        border: none;
      }
      .iubenda-cs-cookie-policy-lnk {
        text-decoration: underline;
        color: #fff;
        font-size: 14px;
        font-weight: 900;
      }
    `}</style>
        <script type="text/javascript">{`
       var _iub = _iub || [];
       _iub.csConfiguration = {"enableCcpa":true,"countryDetection":true,"ccpaAcknowledgeOnDisplay":true,"lang": ${
         getRoute() === 'pt' ? '"pt-BR"' : `"${getRoute()}"`
       },"siteId":1260576,"floatingPreferencesButtonColor":"#ffffff","floatingPreferencesButtonCaptionColor":"#ffffff","cookiePolicyId":83956847, "banner":{ "slideDown":false,"applyStyles":false,"position":"float-bottom-right" }};
    `}</script>

        <script
          type="text/javascript"
          src="//cdn.iubenda.com/cs/ccpa/stub.js"
        />
        <script
          type="text/javascript"
          src="//cdn.iubenda.com/cs/iubenda_cs.js"
          charset="UTF-8"
          async
        />
        {/* <script
          id="ze-snippet"
          src="https://static.zdassets.com/ekr/snippet.js?key=3ce1bbaa-f81d-484c-9781-a13643024255"
        /> */}
      </Helmet>

      <BrowserRouter>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
          language="en"
          useRecaptchaNet="true">
          <Routes />
        </GoogleReCaptchaProvider>
      </BrowserRouter>
    </>
  );
}

export default Sentry.withProfiler(App);
