import {combineReducers, createStore} from 'redux';
import addMsg from '../reducers/add_msg';
import UserReducer from '../reducers/user_reducer';
import SocketReducer from '../reducers/reducer_notication_socket';

const rootReducer = combineReducers({
  step: addMsg,
  user: UserReducer,
  socket: SocketReducer,
});

const store = createStore(rootReducer);
window.store = store;

export default store;
