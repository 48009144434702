const INITIAL_STATE = {
  data: localStorage.getItem('socket') || '',
};

export default function step(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'NOTIFY_SOCKET':
      localStorage.setItem('socket', action.text);
      return {data: action.text};
    case 'NOTIFY_REMOVE':
      localStorage.removeItem('socket');
      return {data: ''};

    default:
      return state;
  }
}
