import React from 'react';
import {Switch} from 'react-router-dom';

const AdvertsRegister = React.lazy(() => import('../views/AdvertsRegister'));
const FavoriteProperties = React.lazy(() =>
  import('../views/FavoriteProperties'),
);
const ForgotPassword = React.lazy(() => import('../views/ForgotPassword'));
const Pay = React.lazy(() => import('../views/FullWidthFinal/Pay'));
const Home = React.lazy(() => import('../views/Home'));
const HowItWorks = React.lazy(() => import('../views/HowItWorks'));
const ListAdsAll = React.lazy(() => import('../views/ListAdsAll'));
const Login = React.lazy(() => import('../views/Login'));
const Matchings = React.lazy(() => import('../views/Matchings'));
const Myads = React.lazy(() => import('../views/Myads'));
const PasswordRecovery = React.lazy(() => import('../views/PasswordRecovery'));
const ConfirmEmail = React.lazy(() => import('../views/ConfirmEmail'));
const Subscriber = React.lazy(() => import('../views/Payments/Subscriber'));
const Preview = React.lazy(() => import('../views/Preview'));
const Profile = React.lazy(() => import('../views/Profile'));
const Proposal = React.lazy(() => import('../views/Proposal'));
const Register = React.lazy(() => import('../views/Register'));
const ShowAdsSpecific = React.lazy(() => import('../views/ShowAdsSpecific'));
const Subscriptions = React.lazy(() => import('../views/Subscriptions'));
const MyProposal2 = React.lazy(() => import('../views/_MyProposal'));
const RouteWrapper = React.lazy(() => import('./RouteWrapper'));
const MyNotifications = React.lazy(() => import('../views/Notications'));

const TestingRoute = React.lazy(() => import('../views/__TestingRoute'));

const base = '/:locale(en|pt|es)?';

export default function Routes() {
  return (
    <Switch>
      <RouteWrapper path={base} exact component={Home} />
      <RouteWrapper path={`${base}/testing`} component={TestingRoute} />
      <RouteWrapper
        path={`${base}/how-it-works`}
        exact
        component={HowItWorks}
      />
      <RouteWrapper
        path={`${base}/advertise/edit`}
        exact
        component={AdvertsRegister}
        isPrivate
      />
      <RouteWrapper
        path={`${base}/advertise`}
        exact
        component={AdvertsRegister}
        isPrivate
      />
      <RouteWrapper path={`${base}/search`} exact component={ListAdsAll} />
      <RouteWrapper path={`${base}/myads`} exact component={Myads} />
      {/* <RouteWrapper
        path={`${base}/myproposal`}
        component={MyProposal}
        isPrivate
      /> */}
      <RouteWrapper
        path={`${base}/myproposal`}
        component={MyProposal2}
        isPrivate
      />
      {/* <RouteWrapper
        path={`${base}/myproposal/:send?`}
        component={MyProposal}
        isPrivate
      /> */}
      <RouteWrapper
        path={`${base}/myads/show/:id`}
        exact
        component={Preview}
        isPrivate
      />
      <RouteWrapper
        path={`${base}/ads/proposal/:id`}
        component={Proposal}
        isPrivate
      />
      <RouteWrapper path={`${base}/profile`} component={Profile} isPrivate />
      <RouteWrapper
        path={`${base}/ads/show/:id/:query?`}
        component={ShowAdsSpecific}
      />
      <RouteWrapper exact path={`${base}/login`} component={Login} isPublic />
      <RouteWrapper
        exact
        path={`${base}/register`}
        component={Register}
        isPublic
      />
      <RouteWrapper
        exact
        path={`${base}/password-recovery/:token`}
        component={PasswordRecovery}
      />
      <RouteWrapper
        exact
        path={`${base}/forgot-password`}
        component={ForgotPassword}
        isPublic
      />
      <RouteWrapper
        exact
        path={`${base}/confirm-email/:token`}
        component={ConfirmEmail}
      />

      <RouteWrapper exact path={`${base}/payment`} component={Subscriber} />
      <RouteWrapper exact path={`${base}/payment/:id`} component={Subscriber} />

      <RouteWrapper
        exact
        path={`${base}/payments/real/state/:plan/:token`}
        component={Subscriber}
      />

      <RouteWrapper exact path={`${base}/payview`} component={Pay} />
      <RouteWrapper
        path={`${base}/favorite-properties`}
        exact
        component={FavoriteProperties}
        isPrivate
      />
      <RouteWrapper
        path={`${base}/matchings`}
        exact
        component={Matchings}
        isPrivate
      />
      <RouteWrapper
        path={`${base}/subscriptions`}
        exact
        component={Subscriptions}
        isPrivate
      />
      <RouteWrapper
        path={`${base}/notifications`}
        exact
        component={MyNotifications}
        isPrivate
      />
    </Switch>
  );
}
