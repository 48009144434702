import {createI18n} from 'react-router-i18n';
import en from './translations/en.json';
import es from './translations/es.json';
import pt from './translations/pt.json';

let locales = ['en', 'pt', 'es'];

function getLang() {
  if (localStorage.getItem('@lang_user')) {
    let langs = localStorage.getItem('@lang_user');
    if (langs === 'pt' || langs === 'pt-BR') {
      locales = ['pt', 'en', 'es'];
    } else if (langs === 'en') {
      locales = ['en', 'pt', 'es'];
    } else if (langs === 'es') {
      locales = ['es', 'pt', 'en'];
    } else {
      locales = ['en', 'pt', 'es'];
    }
  } else {
    if (navigator.language === 'pt' || navigator.language === 'pt-BR') {
      locales = ['pt', 'en', 'es'];
      localStorage.setItem('@lang_user', 'pt');
      localStorage.setItem('length_unit', 'm²');
      localStorage.setItem('currency', 'BRL');
    } else if (navigator.language === 'en') {
      locales = ['en', 'pt', 'es'];
      localStorage.setItem('length_unit', 'sqft');
      localStorage.setItem('@lang_user', navigator.language);
      localStorage.setItem('currency', 'USD');
    } else if (navigator.language === 'es') {
      locales = ['es', 'pt', 'en'];
      localStorage.setItem('length_unit', 'sqft');
      localStorage.setItem('@lang_user', navigator.language);
      localStorage.setItem('currency', 'EUR');
    } else {
      locales = ['en', 'pt', 'es'];
      localStorage.setItem('@lang_user', 'en');
      localStorage.setItem('length_unit', 'sqft');
      localStorage.setItem('currency', 'USD');
    }
  }
}

getLang();

const trad = {
  en: {
    ...en,
    Time: ({day, month, year}) => `${month}/${day}/${year}`,
  },
  es: {
    ...es,
    Time: ({day, month, year}) => `${day}/${month}/${year}`,
  },
  pt: {
    ...pt,
    Time: ({day, month, year}) => `${day}/${month}/${year}`,
  },
};

const translations = trad;

const I18n = createI18n(locales, translations);

export default I18n;
