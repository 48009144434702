import apiCoin from '../apiCoin';

import * as Sentry from '@sentry/react';

export async function getCoin() {
  /*   console.log('chamou'); */
  apiCoin
    .get(`/ipgeo?apiKey=${process.env.REACT_APP_GEOLOCATION_API_KEY}`, {
      headers: {'Content-Type': 'application/json'},
    })
    .then((response) => {
      console.log(response);
      viewCurrency(response.data.currency.code);
    })
    .catch((e) => {
      Sentry.captureException(e);
    });
}

function viewCurrency(currency) {
  if (currency === 'USD') {
    localStorage.setItem('currency', 'USD');
  } else if (currency === 'BRL') {
    localStorage.setItem('currency', 'BRL');
  } else if (currency === 'EUR') {
    localStorage.setItem('currency', 'EUR');
  } else {
    localStorage.setItem('currency', 'USD');
  }
}
