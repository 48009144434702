import React from 'react';
import I18n from '../i18n/i18';
import * as Sentry from '@sentry/react';

export function getRoute() {
  const url = window.location.href;
  const part = url.split('/');
  if (part[3] === 'en' || part[3] === 'es' || part[3] === 'pt') {
    return part[3];
  }

  const langSave = localStorage.getItem('@lang_user');
  return langSave ? langSave : 'en';
}

export function desencrypta(hex = '') {
  const hexAux = hex?.toString(); // force conversion
  let str = '';
  for (let i = 0; i < hexAux?.length; i += 2)
    str += String.fromCharCode(parseInt(hex?.substr(i, 2), 16));
  return str;
}

export function encrypta(str) {
  let hex = '';
  for (let i = 0; i < str.length; i++) {
    hex += `${str.charCodeAt(i).toString(16)}`;
  }
  console.log(typeof hex);
  return hex;
}

export function formatDate(info) {
  let date = new Date(info);

  return (
    <I18n
      t="Time"
      args={{
        day: date.getDate() < 10 ? '0' + date.getDate() : date.getDate(),
        month:
          date.getMonth() + 1 < 10
            ? '0' + (date.getMonth() + 1)
            : date.getMonth() + 1,
        year: date.getFullYear(),
      }}
    />
  );
}

export function formatDateFree(info, freeDays) {
  let date = new Date(info);

  date.setDate(date.getDate() + freeDays);

  return (
    <I18n
      t="Time"
      args={{
        day: date.getDate() < 10 ? '0' + date.getDate() : date.getDate(),
        month:
          date.getMonth() + 1 < 10
            ? '0' + (date.getMonth() + 1)
            : date.getMonth() + 1,
        year: date.getFullYear(),
      }}
    />
  );
}

export function needHelp() {
  const rt = getRoute();

  const enRef = 'https://permutihelp.zendesk.com/hc/en-us';
  const ptRef = 'https://permutihelp.zendesk.com/hc/pt-br';
  const esRef = 'https://permutihelp.zendesk.com/hc/es-419';

  switch (rt) {
    case 'en':
      return window.open(enRef, '_blank');
    case 'es':
      return window.open(esRef, '_blank');
    case 'pt':
      return window.open(ptRef, '_blank');
    default:
      return window.open(enRef, '_blank');
  }
}

export function transformAvatarURL(url = '') {
  if (
    url &&
    typeof url === 'string' &&
    (url?.includes('https://') || url?.includes('http://'))
  ) {
    try {
      const avatar_url = new URL(url);
      return avatar_url?.origin + avatar_url?.pathname;
    } catch (err) {
      Sentry.captureException(err);
      return '';
    }
  } else {
    return '';
  }
}

export function mountYearSelect() {
  let minYear = 1900;
  let maxYear = new Date().getFullYear() + 5;
  let years = [];

  while (minYear <= maxYear) {
    years.push(minYear);
    minYear += 1;
  }

  const orderedYears = years.sort((a, b) => b - a);

  return orderedYears;
}

export function calculateFreeDaysRemaining({endDate}) {
  const end = new Date(endDate);
  const today = new Date();

  end.setDate(end.getDate());

  if (today > end) {
    return 0;
  }

  const diffTime = Math.abs(end.getTime() - today.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 3600 * 24));

  return diffDays;
}
