export const AVATAR_SAVE_TAG = '@user_avatar_url';

const INITIAL_STATE = {
  avatar: localStorage.getItem(AVATAR_SAVE_TAG) || '',
};

export default function step(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_AVATAR':
      return {
        avatar: action.payload,
      };
    default:
      return state;
  }
}
